<template>
  <div class="userMessage">
    <div>
      <img :src="userImage" alt="">
    </div>
    <div class="userMesItem">
      <span>登陆账号: {{userMes.tel}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userImage: require('../../../assets/images/244.png'),
      userMes: ''
    }
  },
  created() {
    this.userMes = this.$store.state.common.sessionInfo
    console.log(this.$store.state.common.sessionInfo, 'this.userMes')
  }
}
</script>

<style lang="scss" scoped>
.userMessage {
  padding: 15px;
  .userMesItem {
    margin-top: 15px;
  }
}
</style>